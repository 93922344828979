export const apiRoot = {
    production: {
        qida_api: `http://${window.location.host}/v1`,
        component_api: 'https://component.u.ccb.com/v1/',
        trabase_api: 'https://trabase.u.ccb.com/cu/api/v1/',
        log_api: 'https://log.u.ccb.com/',
        receiv_api: 'https://receiv.u.ccb.com/',
        media_api: 'https://media-w.u.ccb.com/'
    },
    development: {
        // qida_api: 'http://localhost:18286/',
        qida_api: 'http://zhenrener.com/v1',
        component_api: 'https://component1.u.ccb.com/v1/',
        trabase_api: 'https://trabase1.u.ccb.com/cu/api/v1/',
        log_api: 'https://log1.u.ccb.com/',
        receiv_api: 'https://receiv1.u.ccb.com/',
        media_api: 'https://media1-w.u.ccb.com/'
    }
}
