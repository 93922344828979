import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cardShow:false, //答题卡显示隐藏
    questionsNum:0, //考试题目数量
    currentNum:0,  // 当前第1题
    currentExerciseNum:0 ,// 当前练习第1题
    tab : 2, // 首页tab状态
  },
  mutations: {
    updateCard(state,b){
      state.cardShow = b
    },
    updateQuestionsNum(state,num){
      state.questionsNum = num
    },
    updateCurrentNum(state,num){
      state.currentNum = num
    },
    updateCurrentExerciseNum(state,num){
      state.currentExerciseNum = num
    },
    updateTab(state,num){
      state.tab = num
    }

  },
  actions: {
    updateQuestionsNumAsync(store,num){
      store.commit('updateQuestionsNum',num)
    }
  },
  modules: {}
});
