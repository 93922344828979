import ajax from './ajax'
import sha256 from 'js-sha256'

export const getAuth = orgId => ajax.get(`/qida_api/orgs/${orgId}/toolBarListAll`)

//用户信息接口防重放优化
export const encryptionAjaxParams = async (params = {}) => {
    // 32位随机字符串
    let randomStr =
        Math.random()
            .toString()
            .slice(2, 12) +
        Math.random()
            .toString()
            .slice(2, 13) +
        Math.random()
            .toString()
            .slice(2, 13)
    let token =
        window.localStorage.getItem('token') ||
        Math.random()
            .toString()
            .slice(2, 14)
    let nonce = randomStr.slice(0, parseInt(Math.random() * 31) + 1)
    let xts = new Date().getTime()
    let timestamp
    // debugger
    await ajax
        .get('/qida_api/udp/open/server/time')
        .then(time => {
            timestamp = time
        })
        .catch(() => {})
    let tktk = token.slice(0, 5) + timestamp + token.slice(5, 8) + nonce + token.slice(8, 10) + xts + token.slice(10, token.length)
    let signature = sha256(tktk)
    return Object.assign({}, params, {
        headers: {
            // ...params.headers,

            token,
            nonce,
            'x-ts': xts,
            timestamp,
            signature
        }
    })
}

export const userInfo = async () => {
    const config = await encryptionAjaxParams()
    return ajax.get(`/qida_api/userinfo`, config)
}
