var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-tabbar",
    {
      model: {
        value: _vm.active,
        callback: function($$v) {
          _vm.active = $$v
        },
        expression: "active"
      }
    },
    [
      _c("van-tabbar-item", { attrs: { name: "home", icon: "home-o" } }, [
        _vm._v("标签")
      ]),
      _c("van-tabbar-item", { attrs: { name: "category", icon: "search" } }, [
        _vm._v("标签")
      ]),
      _c(
        "van-tabbar-item",
        { attrs: { name: "shop-cart", icon: "friends-o" } },
        [_vm._v("标签")]
      ),
      _c("van-tabbar-item", { attrs: { name: "setting", icon: "setting-o" } }, [
        _vm._v("标签")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }