import Axios from 'axios'
import { Toast } from 'vant'
import { apiRoot } from '@/config/apiRoot'
import store from '../store'
// Create an Axios instance
const instance = Axios.create({
    timeout: 300000,
    headers: {
        'Content-Type': 'application/json'
    }
})

// 防止重复提交
let pendingArr = []
let cancelToken = Axios.CancelToken
let removePending = config => {
    // console.log("pendingArr:", pendingArr);
    if (pendingArr.length) {
        for (let i in pendingArr) {
            // 若当前请求在数组中存在时
            if (pendingArr[i].url === config.url + '&' + config.method) {
                pendingArr[i].fn() // 执行取消操作
                pendingArr.splice(i, 1) // 把这条记录从数组中移除
            }
        }
    }
}
// request interceptor
instance.interceptors.request.use(
    config => {
        // 设置baseURL
        if (process.env.VUE_APP_NODE_ENV == 'development') {
            config.baseURL = apiRoot.development.qida_api // 'http://localhost:18286/'
        }
        if (process.env.VUE_APP_NODE_ENV === 'production') {
            config.baseURL = apiRoot.production.qida_api
        }
        // 设置token
        let token = window.localStorage.getItem('token')
        if (token) config.headers.common['token'] = token

        // 设置全局跟踪号
        let rndNum = ''
        for (let i = 0; i < 6; i++) {
            rndNum = rndNum + Math.round(Math.random() * 10).toString()[0]
        }
        if (config.url.indexOf('trabase') > -1) config.headers['c-business-id'] = new Date().getTime() + rndNum
        console.log(config.baseURL, '----', config.url)
        // 设置sourse
        config.headers['source'] = window.sessionStorage.getItem('source')

        // 在一个ajax发送前执行一下取消操作
        removePending(config)
        config.cancelToken = new cancelToken(c => {
            store.commit('addCancelToken', c)
            pendingArr.push({
                url: config.url + '&' + config.method,
                fn: c
            })
        })
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

// response interceptor
instance.interceptors.response.use(
    response => {
        console.log(response, '--------res')
        if (response.status !== 200 && response.status !== 204) {
            if (!response.config || !response.config.errToastNone) Toast.fail('服务器异常！请稍后重试...')
            return Promise.reject(response)
        }

        // 导出文件(指定使用二进制接收), 需先解析成json格式, 判断响应是否正确
        if (response.config.responseType && response.config.responseType === 'arraybuffer') {
            let enc = new TextDecoder('utf-8')
            let json = JSON.parse(enc.decode(new Uint8Array(response.data)))
            if (json.code === 200) {
                return Promise.resolve(response.data)
            } else {
                return Promise.reject(response)
            }
        } else {
            // 根据返回体不同类型返回
            if (response.data) {
                // 返回体为云②类型
                if (response.data['code'] !== null && response.data['code'] !== undefined) {
                    if (response.data['code'] === 0 || response.data['code'] === 200 || (response.data['code'] && isNaN(Number(response.data['code'])))) {
                        return response.data
                    } else {
                        console.log('云②类型失败:', response)
                        if (response.config.url.indexOf('noErrToast') < 0) Toast.fail(response.data.message || response.data.msg)
                        return Promise.reject(response)
                    }
                }

                // 返回体为自研类型
                else if (response.data['C-API-Status']) {
                    if (response.data && response.data['C-API-Status'] === '00') {
                        return response.data['C-Response-Body']
                    } else {
                        console.log('自研类型失败:', response)
                        // 临时兼容方案，当请求url带有errorMsg参数，即此接口已做报错信息规范化处理，此时可直接展示报错信息
                        if (response.config.url.indexOf('noErrToast') < 0) {
                            Toast.fail(response.config.url.indexOf('errorMsg') >= 0 ? response.data.message : response.data['C-Response-Desc'])
                        }
                        return Promise.reject(response)
                    }
                }

                // 返回体为云①类型
                else {
                    return response.data
                }
            } else {
                return Promise.reject(response)
            }
        }
    },
    error => {
        console.log('error.response----', error)
        // 401, 登录失效
        if (error && error.response && error.response.status === 401) {
            // 跳转至主站登录页，附带当前地址，便于登陆成功后跳回
            try {
                window.localStorage.clear()
                window.sessionStorage.clear()
            } catch (e) {
                window.clearCookieForKey()
            }
            let rurl = window.location.href
            window.setLocalStorage('returnUrl', rurl)
            let url = window.location.origin + '/#/login'
            window.location.replace(url)
        } else if (error && error.response && error.response.data && error.response.data.message) {
            Toast(error.response.data.message || '服务器异常！请稍后重试...')
        }

        return Promise.reject(error.response)
    }
)

export default instance
