import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/iconfont/iconfont.css'
import directive from '@/directives'
import '@/utils/flexible.js'
import './assets/css/reset.css'

import vant from 'vant'
import 'vant/lib/index.css'

import { Toast, InfiniteScroll, Indicator, Actionsheet, Popup, Swipe, SwipeItem } from 'mint-ui'

import ElementUI from 'element-ui'
// 引入
import echarts from 'echarts'

// 注册
Vue.prototype.$echarts = echarts
Vue.prototype.$toast = Toast
// Vue.use(vant)
// Vue.use(ElementUI)
Vue.component(Popup.name, Popup)
Vue.component(Actionsheet.name, Actionsheet)
Vue.component(Swipe.name, Swipe)
Vue.component(SwipeItem.name, SwipeItem)
import VConsole from 'vconsole'
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'production') {
    new VConsole()
}
Vue.config.productionTip = false

// 全局注册过滤器
const filtersFiles = require.context('@/filter', true, /\.js$/)
filtersFiles.keys().reduce((filters, filtersPath) => {
    const value = filtersFiles(filtersPath)
    Object.keys(value).forEach(key => {
        Vue.filter(key, value[key])
    })
}, {})

Vue.use(directive)

// 是否在建行大学app中
if (window.isApp) {
    console.log('建行大学app中--------', window.isApp)
    let t = window.yxt.token
    let h5token = window.localStorage.getItem('token')
    if (t !== h5token) {
        let c = window.yxt.clienttoken
        window.localStorage.setItem('clientKey', c)
        let d = window.yxt.deviceid
        window.localStorage.setItem('deviceId', d)
    }
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
