/**
 * @desc 判断数组是否有效
 * @author
 * @param {Array} arr 目标数组
 * @return Boolean
 */

export const isValidArr = arr => (!arr || Object.prototype.toString.call(arr) !== '[object Array]' || !arr.length ? false : true)

/**
 * @desc 数组对象去重
 * @author
 * @param {Array} arr 目标数组
 * @param {String} key 目标属性值
 * @return Array
 */
export const uniqueArrByKey = (arr, key) => {
    if (!isValidArr(arr)) return []

    var res = [],
        obj = {}
    for (var i = 0; i < arr.length; i++) {
        if (!obj[arr[i][key]]) {
            res.push(arr[i])
            obj[arr[i][key]] = true
        }
    }

    return res
}

/*
 * @desc format数组menu，剔除不显示在左侧导航内的菜单
 * @author
 * @param arr 目标数组
 * @param key 目标属性值
 * @return Array
 */
export const formatMenu = (arr = [], key) => {
    // let arr = JSON.parse(JSON.stringify(menus))
    if (!isValidArr(arr)) return []
    // arr.forEach((item, index) => {
    // 	if(!item[key]){
    // 		arr.splice(index, 1)
    // 	}
    // 	if(item.children && item.children){
    // 		formatMenu(item.children, key)
    // 	}
    // })

    for (var i = arr.length - 1; i >= 0; i--) {
        if (!arr[i][key]) {
            arr.splice(i, 1)
        } else {
            if (arr[i].children && arr[i].children.length) {
                formatMenu(arr[i].children, key)
            }
        }
    }
    return arr
}

/*
 * @desc format数组menu，剔除不显示在左侧导航内的菜单
 * @author
 * @param arr 目标数组
 * @param key 目标属性值
 * @return Array
 */
export const formatArr = (arr = []) => {
    arr.forEach(item => {
        if (item.children) {
            if (!item.children.length) {
                delete item.children
            } else {
                formatArr(item.children)
            }
        }
    })
    return arr
}

/*
 * @desc 根据当前路由code，获取面包屑数组
 * @author
 * @param menus 权限菜单
 * @param code 当前路由code码
 * @return Array
 */
export const findBreadcrumbDataList = (menus = [], code) => {
    let arr = JSON.parse(JSON.stringify(menus))
    if (!isValidArr(arr)) return []

    let res = []
    arr.forEach(item => {
        if (item.code === code) {
            res.push(item)
            return res
        }

        if (item.children && item.children.length) {
            const subs = findBreadcrumbDataList(item.children, code)
            if (subs.length) res = [item, ...subs]
        }
    })
    return res
}

/*
 * @desc 根据当前路由code，获取当前菜单
 * @author
 * @param menus 权限菜单
 * @param code 当前路由code码
 * @return Array
 */
export const findMenuByCode = (menus = [], code) => {
    let obj = {},
        hasFound = false
    const fn = (arr, value) => {
        if (Array.isArray(arr) && !hasFound) {
            // 判断是否是数组并且没有的情况下，
            arr.forEach(item => {
                if (item.code === value) {
                    // 数据循环每个子项，并且判断子项下边是否有id值
                    obj = item // 返回的结果等于每一项
                    hasFound = true // 并且找到id值
                } else if (item.children && item.children.length) {
                    fn(item.children, value) // 递归调用下边的子项
                }
            })
        }
    }

    fn(menus, code)
    return obj
}

/**
 * @desc 字符串格式 转 驼峰格式
 * @author
 * @param {String} name 组件名
 * @param {Boolean} type 是否为大驼峰 (默认true为大驼峰， false为小驼峰)
 * @returns {String} 符合驼峰格式的名称
 */
export const strFormat1 = (name, type = true) => {
    name = type ? name.substring(0, 1).toUpperCase() + name.substring(1) : name
    let idx = 0
    do {
        idx = name.indexOf('-')
        let nextChar = name.substr(idx + 1, 1)
        name = name.replace('-' + nextChar, nextChar.toUpperCase())
    } while (idx > 0)
    return name
}

/**
 * @desc 数组元素互换位置
 * @author
 * @param {Array} arr 目标数组
 * @param {Number} index1 目标索引1
 * @param {Number} index2 目标索引2
 * @returns {Array} 转换后数组 swapArr(arr, index1, index2)
 */
export const swapArr = (arr, index1, index2) => {
    arr[index1] = arr.splice(index2, 1, arr[index1])[0]
    return arr
}

/**
 * @desc 数组元素置顶移动
 * @author
 * @param {Array} arr 目标数组
 * @param {Number} index 目标索引
 */
export const toFirst = (arr, index) => {
    if (index !== 0) arr.unshift(arr.splice(index, 1)[0])
}

/**
 * @desc 数组元素上移动一格
 * @author
 * @param {Array} arr 目标数组
 * @param {Number} index 目标索引
 */
export const moveUp = (arr, index) => {
    if (index !== 0) {
        arr[index] = arr.splice(index - 1, 1, arr[index])[0]
    } else {
        arr.push(arr.shift())
    }
}

/**
 * @desc 数组元素下移动一格
 * @author
 * @param {Array} arr 目标数组
 * @param {Number} index 目标索引
 */
export const moveDown = (arr, index) => {
    if (index !== arr.length - 1) {
        arr[index] = arr.splice(index + 1, 1, arr[index])[0]
    } else {
        arr.unshift(arr.splice(index, 1)[0])
    }
}

/**
 * @desc 将对象数组中具有父子关系的数据换成树形结构
 * @author
 * @param {Array} data 带转换成树形结构的对象数组
 * @param {Object} attributes 对象属性,默认为 { id: 'id', parentId: 'parentId' }
 * @returns {Array}
 */
export const toTreeData = (
    data,
    attributes = {
        id: 'id',
        parentId: 'parentId'
    }
) => {
    if (!isValidArr(data)) return []

    // let arr = JSON.parse(JSON.stringify(data));
    let arr = data
    let tree = []

    // 找寻根节点
    for (let i = 0; i < arr.length; i++) {
        if (!arr[i][attributes.parentId]) {
            tree.push(arr[i])
            arr.splice(i, 1)
            i--
        }
    }

    run(tree)

    // 找寻子树
    function run(chiArr) {
        if (arr.length) {
            for (let i = 0; i < chiArr.length; i++) {
                for (let j = 0; j < arr.length; j++) {
                    if (chiArr[i][attributes.id] === arr[j][attributes.parentId]) {
                        delete chiArr[i].component
                        chiArr[i].children = []
                        chiArr[i].children.push(arr[j])
                        arr.splice(j, 1)
                        j--
                    }
                }
                if (chiArr[i].children && chiArr[i].children.length) run(chiArr[i].children)
            }
        }
    }

    return tree
}

//判断一个对象是否存在key，如果传入第二个参数key，则是判断这个obj对象是否存在key这个属性
export const hasKey = (obj, key) => {
    if (key) return key in obj
    else {
        let keysArr = Object.keys(obj)
        return keysArr.length
    }
}

//判断两个对象是否相等，这两个对象的值只能是数字或字符串
export const objEqual = (obj1, obj2) => {
    const keysArr1 = Object.keys(obj1)
    const keysArr2 = Object.keys(obj2)
    if (keysArr1.length !== keysArr2.length) return false
    else if (keysArr1.length === 0 && keysArr2.length === 0) return true
    else return !keysArr1.some(key => obj1[key] != obj2[key])
}

/**
 * 延时
 * @param millsecond 延时时间 单位毫秒
 */
export async function delay(millsecond) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve()
        }, millsecond)
    })
}

/**
 * @desc   获取当前设备的默认source
 * @author
 * @return { String }
 */
export const getChannelSourceDefault = () => {
    let source = '504'
    if (IsPC()) {
        source = '501'
    } else {
        if (window.isApp) {
            source = isIos() ? '502' : '503'
        } else if (window.PJF && window.PJF.client && window.PJF.client.isInClient()) {
            source = '506'
        }
    }
    return source
}

/**
 * @desc   获取默认主站host,masterStationHost
 * @author
 * @return { String }
 */
export const getMasterStationHostDefault = () => {
    let masterStationHost = '',
        currentHost = window.location.host
    switch (currentHost) {
        case 'm.u.ccb.com':
            masterStationHost = 'u.ccb.com'
            break
        case 'vt-m1.u.ccb.com':
            masterStationHost = 'vt-el.u.ccb.com'
            break
        default:
            masterStationHost = 'el.u.ccb.com'
    }
    return masterStationHost
}

// 当前设备是PC
export const IsPC = () => {
    var userAgentInfo = navigator.userAgent
    var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod', 'WindowsWechat']
    var flag = true
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false
            break
        }
    }
    return flag
}

// 当前设备是IOS
export const isIos = () => !!window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

// 当前设备是安卓
export const isAndroid = () => window.navigator.userAgent.indexOf('Android') > -1 || u.indexOf('Linux') > -1

// 当前设备是在微信
export const isWeixin = () => window.navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1

//防抖
export const debounce = (fnName, time) => {
    let timeout = null
    return function() {
        if (timeout) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            this[fnName]()
        }, time)
    }
}
//节流
export const throttle = (fn, time) => {
    let _arguments = arguments
    let canRun = true
    return function() {
        if (!canRun) return
        canRun = false
        setTimeout(() => {
            fn.call(this, _arguments)
            canRun = true
        }, time)
    }
}
//将时间格式化成YYYY/MM/dd HH:mm:ss
export const DateFormat = time => {
    return (time || '').replace(/\.\d+/, '').replace(/-/g, '/')
}

// 获取url参数
export const getUrlParam = name => {
    const arr = new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href)
    if (arr !== null) {
        return decodeURIComponent(arr[1].replace(/\+/g, '%20'))
    }
    return ''
}
// get拼接参数
export const params = obj => {
    let result = ''
    let item
    for (item in obj) {
        result += `&${item}=${obj[item]}`
        /* if (obj[item] && String(obj[item])) {
     result += `&${item}=${obj[item]}`;
   } */
    }
    if (result) {
        result = '?' + result.slice(1)
    }
    return result
}
// 组装成分页参数，mode为true表示正序asc，false表示倒序desc(针对后端分页传参处理)
export const PagingCriteria = (pageIndex, pageSize, sort, mode) => {
    pageIndex = pageIndex ? pageIndex : 1
    pageSize = pageSize ? pageSize : 10
    var paging = {}
    paging.limit = pageSize
    paging.offset = (pageIndex - 1) * pageSize
    return paging
}

export const changeTheme = p => {
    let obj = [
        [
            {
                key: 'blueTheme',
                val: '#FE6700'
            },
            {
                key: 'lightBlueTheme',
                val: '#f7d5b7'
            },
            {
                key: 'icon1bg',
                val: `url(${require('../assets/theme/icon01.png')})`
            },
            {
                key: 'icon2bg',
                val: `url(${require('../assets/theme/icon02.png')})`
            },
            {
                key: 'icon3bg',
                val: `url(${require('../assets/theme/icon03.png')})`
            },
            {
                key: 'icon4bg',
                val: `url(${require('../assets/theme/icon04.png')})`
            },
            {
                key: 'bg',
                val: `url(${require('../assets/theme/bg00.png')})`
            }
        ],
        [
            {
                key: 'blueTheme',
                val: '#0066B3'
            },
            {
                key: 'lightBlueTheme',
                val: '#d4eafa'
            },
            {
                key: 'icon1bg',
                val: `url(${require('../assets/theme/icon1.png')})`
            },
            {
                key: 'icon2bg',
                val: `url(${require('../assets/theme/icon2.png')})`
            },
            {
                key: 'icon3bg',
                val: `url(${require('../assets/theme/icon3.png')})`
            },
            {
                key: 'icon4bg',
                val: `url(${require('../assets/theme/icon4.png')})`
            },
            {
                key: 'bg',
                val: `url(${require('../assets/theme/bg.png')})`
            }
        ]
    ][p - 1]
    obj.forEach(e => {
        document.getElementsByTagName('body')[0].style.setProperty(`--${e.key}`, e.val)
    })
}

export const formatTime = s => {
    var days = parseInt(s / (60 * 60 * 24))
    var hours = days * 24 + parseInt((s % (60 * 60 * 24)) / (60 * 60))
    var minutes = parseInt((s % (60 * 60)) / 60)
    var seconds = s % 60
    return [hours, minutes, seconds]
}

export const getUrlParameter = field => {
    //获取Url 参数
    var GET = {}
    var canshu = window.location.hash.split('?')[1] == undefined ? '' : window.location.hash.split('?')[1]
    if (canshu != '') {
        var strarr = canshu.split('&')
        for (let i = 0; i < strarr.length; i++) {
            var xx = strarr[i]
            var mykey = xx.split('=')[0]
            var myvalue = xx.split('=')[1]
            GET[mykey] = myvalue
        }
    }
    return GET[field]
}

// 名称转译符
export const htmlDecodeToStr = str => {
    let s = ''
    if (str && str !== '') {
        s = str.replace(/&amp;/g, '&')
        s = s.replace(/&lt;/g, '<')
        s = s.replace(/&gt;/g, '>')
        s = s.replace(/&nbsp;/g, ' ')
        s = s.replace(/&#39;/g, "'")
        s = s.replace(/&quot;/g, '"')
        s = s.replace(/&#183;/g, '·')
    }
    return s
}

export const deepClone = sourceObj => {
    const targetObj = sourceObj.constructor === Array ? [] : {} // [] -> Array(基类)  {} -> Object
    for (let key in sourceObj) {
        if (sourceObj.hasOwnProperty(key)) {
            //判断key是否为自身含有的属性
            if (sourceObj[key] && typeof sourceObj[key] === 'object') {
                //引用数据类型
                targetObj[key] = deepClone(sourceObj[key]) //递归
            } else {
                //基本数据类型
                targetObj[key] = sourceObj[key]
            }
        }
    }
    return targetObj
}
