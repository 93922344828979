var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "transition",
        { attrs: { name: _vm.mainName } },
        [
          _vm.$route.meta.keepAlive
            ? _c(
                "keep-alive",
                [
                  _vm.$route.meta.keepAlive
                    ? _c("router-view", { attrs: { id: "view" } })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          !_vm.$route.meta.keepAlive && _vm.isRouterAlive
            ? _c("router-view", { attrs: { id: "view" } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: _vm.navName } },
        [
          _vm.isShowNav
            ? _c("footer-nav", {
                attrs: { activeNavIndex: _vm.activeNavIndex }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }