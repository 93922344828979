/* eslint-disable */
// 时间格式化(YYYY-MM(mm)-DD(dd) HH(hh):MM(mm):SS(ss))
export const dateTimeFilter = (value, rule) => {
  // 保持两位数
  let two = (number) => (number > 9 ? "" : "0") + number;

  let time = new Date(value);

  let YYYY = time.getFullYear();
  let mm = time.getMonth() + 1;
  let dd = time.getDate();

  let hh = time.getHours();
  let mi = time.getMinutes();
  let ss = time.getSeconds();

  // 两位数
  let MM = two(time.getMonth() + 1);
  let DD = two(time.getDate());

  let HH = two(time.getHours());
  let MI = two(time.getMinutes());
  let SS = two(time.getSeconds());

  // 输出
  return rule
    .replace(/\:MM/g, ":MI")
    .replace(/\:mm/g, ":mi")
    .replace("YYYY", YYYY)
    .replace("mm", mm)
    .replace("dd", dd)
    .replace("hh", hh)
    .replace("mi", mi)
    .replace("ss", ss)
    .replace("MM", MM)
    .replace("DD", DD)
    .replace("HH", HH)
    .replace("MI", MI)
    .replace("SS", SS);
};

export const formatDuring = (mss) => {
  var days = parseInt(mss / (1000 * 60 * 60 * 24));
  var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = (mss % (1000 * 60)) / 1000;
  return (
    (days > 0 ? days + " 天 " : "") +
    (hours > 0 ? hours + " 小时 " : "") +
    (minutes > 0 ? minutes + " 分钟 " : "") +
    (seconds > 0 ? Math.round(seconds) + " 秒 " : "")
  );
};

export const dateFormat = (date, format = "Y.M.D") => {
  if (date === null || date === "" || typeof date === "undefined") {
    return "";
  }
  if (typeof date === "string") {
    date = new Date(date.replace(/-/g, "/").replace(/\.[0-9]*$/g, ""));
  }
  let dateStr = format;
  dateStr = dateStr
    .replace("YYYY", date.getFullYear())
    .replace(
      "MM",
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    )
    .replace("DD", date.getDate() < 10 ? "0" + date.getDate() : date.getDate())
    .replace(
      "HH",
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
    )
    .replace(
      "mm",
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
    )
    .replace(
      "ss",
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
    );
  dateStr = dateStr
    .replace("Y", date.getFullYear())
    .replace("M", date.getMonth() + 1)
    .replace("D", date.getDate())
    .replace("H", date.getHours())
    .replace("m", date.getMinutes())
    .replace("s", date.getSeconds());
  return dateStr;
};
