// 定义路由
let routes = [
    {
        path: '/examhome',
        name: 'examhome',
        component: resolve => require(['../view/exam/index'], resolve),
        meta: {
            title: '首页',
            code: 'examhome',
            tabbar: false //是否显示下面的tabbar
        }
    },
    {
        path: '/exam/index', //1. : 必传   2. :?选传
        name: 'home',
        component: resolve => require(['../view/exam/index'], resolve),
        meta: {
            code: 'exam'
        },
        redirect: { name: 'introduce' },
        children: [
            {
                path: 'introduce',
                name: 'introduce',
                component: resolve => require(['../view/exam/introduce'], resolve),
                meta: {
                    title: '学习路径',
                    tabbar: true //是否显示下面的tabbar
                }
            },
            {
                path: 'questionsList',
                name: 'questionsList',
                component: resolve => require(['../view/exam/questionsList'], resolve),
                meta: {
                    title: '学习路径',
                    tabbar: true //是否显示下面的tabbar
                }
            },
            {
                path: 'examStatistics',
                name: 'examStatistics',
                component: resolve => require(['../view/exam/examStatistics'], resolve),
                meta: {
                    title: '学习路径',
                    tabbar: true //是否显示下面的tabbar
                }
            },
            {
                path: 'answerList',
                name: 'answerList',
                component: resolve => require(['../view/exam/answerList'], resolve),
                meta: {
                    title: '学习路径',
                    tabbar: true //是否显示下面的tabbar
                }
            },
            {
                path: 'exerciseList',
                name: 'exerciseList',
                component: resolve => require(['../view/exam/exerciseList'], resolve),
                meta: {
                    title: '练习答题',
                    tabbar: true //是否显示下面的tabbar
                }
            }
        ]
    },
    {
        path: '/hwk/index',
        name: 'hwkindex',
        component: resolve => require(['../view/hwk/index'], resolve),
        meta: {
            title: '消息列表',
            code: 'cmooc-',
            tabbar: false
        }
    },
    {
        path: '/hwk/detail',
        name: 'hwkdetail',
        component: resolve => require(['../view/hwk/detail'], resolve),
        meta: {
            title: '详情',
            code: 'hwk',
            tabbar: false
        }
    },
    {
        path: '/hwk/echart',
        name: 'hwkechart',
        component: resolve => require(['../view/hwk/echart'], resolve),
        meta: {
            title: '小测曲线图',
            code: 'hwk',
            tabbar: false
        }
    },
    {
        path: '/login',
        name: 'hwklogin',
        component: resolve => require(['../view/hwk/login'], resolve),
        meta: {
            title: '登录',
            code: 'hwk',
            tabbar: false
        }
    },
    {
        path: '/eindex',
        name: 'eindex',
        component: resolve => require(['../view/hwk/manage'], resolve),
        meta: {
            title: '首页',
            code: 'hwk',
            tabbar: false
        }
    },
    {
        path: '/hwk/input',
        name: 'hwkinput',
        component: resolve => require(['../view/hwk/addInfoItem'], resolve),
        meta: {
            title: '新增',
            code: 'hwk',
            tabbar: false
        }
    }
]

// 404 页面
routes.push({
    path: '*',
    component: resolve => require(['../view/404.vue'], resolve)
})

export default routes
