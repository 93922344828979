/**
 * 可伸缩布局方案
 * rem计算方式：设计图尺寸px / 100 = 实际rem  例: 100px = 1rem
 */

!(function(window) {
  var docWidth = 750, // 设计图文档宽度
    baseSize = 75,
    docEl = window.document.documentElement,
    resizeEvent = "orientationchange" in window ? "orientationchange" : "resize";

  // 根据屏幕宽度动态计算html根元素fontSize，单倍图下，小于320px不再缩小, 大于540px不再放大；
  var recalc = (function refreshRem() {
    var clientWidth = docEl.getBoundingClientRect().width;
    var acceptClientWidth = Math.min(Math.max(clientWidth, 320), 540);
    docEl.style.fontSize = baseSize * (acceptClientWidth / docWidth) + "px";
    return refreshRem;
  })();

  // 添加倍屏标识，安卓倍屏为1
  docEl.setAttribute(
    "data-dpr",
    window.navigator.appVersion.match(/iphone/gi) ? window.devicePixelRatio : 1
  );

  // 添加IOS标识
  if (/iP(hone|od|ad)/.test(window.navigator.userAgent)) {
    docEl.classList.add("ios");

    // IOS8以上给html添加hairline样式，以便特殊处理
    if (parseInt(window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)[1], 10) >= 8)
      docEl.classList.add("hairline");
  }

  if (!window.document.addEventListener) return;
  window.addEventListener(resizeEvent, recalc, false);
  window.document.addEventListener("DOMContentLoaded", recalc, false);
})(window);
