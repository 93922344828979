import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import { getChannelSourceDefault, getMasterStationHostDefault } from '@/utils'
import { getAuth, userInfo } from '@/api/account.api'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '../store'
NProgress.configure({
    showSpinner: false
})

Vue.use(Router)

const router = new Router({
    mode: 'hash',
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
    NProgress.start()
    store.commit('clearCancelToken')
    let title
    if (to.meta.title === '社区') {
        title = '社区'
    } else {
        title = store.getters['cmooc/title'] || to.meta.title
    }
    if (to.name !== from.name) {
        window.localStorage.setItem('returnFromUrl', from.name)
    }

    // source
    if (to.query.source) {
        window.sessionStorage.setItem('source', to.query.source)
    } else {
        if (!window.sessionStorage.getItem('source')) {
            window.sessionStorage.setItem('source', getChannelSourceDefault())
        }
    }

    /**
     * @desc   sass化域名
     * @remark :
     *  如果url参数存在domainName，需做sass化处理；
     *  如果url参数同时还存在token，则一定是通过跳转接口（/udp/open/redirect），该domainName和token确定一致；
     *  如果url参数不存在token，此时若缓存内存在domainName，则需判断两者是否一致，否则清空缓存，跳转至登录页；
     *  如果url参数不存在token，且缓存内不存在domainName，则清空缓存，跳转至登录页；
     *
     *  如果url参数不存在domainName，且缓存内不存在domainName，则认为是建大站点，根据域名设置domainName；
     */
    if (to.query.domainName) {
        if (to.query.token) {
            window.localStorage.setItem('domainName', to.query.domainName)
            if (window.PJF && window.PJF.client && window.PJF.client.isInAndroid()) {
                window.localStorage.setItem('domainName', to.query.domainName)
            }
        } else {
            if (window.localStorage.getItem('domainName')) {
                if (window.localStorage.getItem('domainName') !== to.query.domainName) {
                    window.localStorage.clear()
                    window.sessionStorage.clear()
                    window.localStorage.setItem('domainName', to.query.domainName)
                    window.localStorage.setItem('returnUrl', window.location.href)
                    if (window.PJF && window.PJF.client && window.PJF.client.isInAndroid()) {
                        window.PJF && window.PJF.client && window.PJF.client.storage.localClear()
                        window.localStorage.setItem('domainName', to.query.domainName)
                        window.localStorage.setItem('returnUrl', window.location.href)
                    }
                    window.location.replace(window.location.protocol + '//' + window.location.host + '/#/login')
                }
            } else {
                window.localStorage.clear()
                window.sessionStorage.clear()
                window.localStorage.setItem('domainName', to.query.domainName)
                window.localStorage.setItem('returnUrl', window.location.href)
                if (window.PJF && window.PJF.client && window.PJF.client.isInAndroid()) {
                    window.PJF && window.PJF.client && window.PJF.client.storage.localClear()
                    window.localStorage.setItem('domainName', to.query.domainName)
                    window.localStorage.setItem('returnUrl', window.location.href)
                }
                window.location.replace(window.location.protocol + '//' + window.location.host + '/#/login')
            }
        }
    } else {
        if (!window.localStorage.getItem('domainName')) {
            window.localStorage.setItem('domainName', getMasterStationHostDefault())
            if (window.PJF && window.PJF.client && window.PJF.client.isInAndroid()) {
                window.localStorage.setItem('domainName', getMasterStationHostDefault())
            }
        }
    }
    // app壳里头部
    if (window.isApp) {
        let param = {
            isShowheader: true,
            isShowBack: true,
            name: title
        }
        window.yxt.ui.navigation.setTitle({
            param: Object.assign(
                {
                    // 参数
                    isShowheader: param.isShowheader,
                    isShowBack: param.isShowBack,
                    name: param.name
                },
                param
            )
        })
    }
    if (window.PJF.client.isInClient()) {
        console.log('建行员工渠道app中--------', window.PJF.client.isInClient())
        window.PJF.client.header.setTitle(title)
        window.PJF.client._callShell('setHeaderRightBtn', { text: '关闭', type: 'text' })
        window.PJF.client.off('_header_right_btn_clicked_').on('_header_right_btn_clicked_', function(e) {
            window.PJF.client.webview._close()
        })
        // url参数带有自动关闭标识
        if (to.query.autoclose === '1') {
            window.PJF.client._callShell('setHeaderLeftBtn', { text: '返回', actionType: 'default' })
            window.PJF.client.off('_header_left_btn_clicked_').on('_header_left_btn_clicked_', function(e) {
                window.PJF.client.webview._close()
            })
        } else {
            window.PJF.client._callShell('setHeaderLeftBtn', { text: '返回', actionType: 'default' })
            window.PJF.client.off('_header_left_btn_clicked_').on('_header_left_btn_clicked_', function(e) {
                history.back()
            })
        }
    }

    if (to.query.token) {
        window.localStorage.setItem('token', to.query.token)
        userInfo()
            .then(res => {
                window.localStorage.setItem('userName', res.fullName)
                window.localStorage.setItem('userId', res.userId)
                window.localStorage.setItem('orgId', res.orgId)
                window.localStorage.setItem('orgName', res.webSiteName)
                window.localStorage.setItem('websitepicurl', res.logoUrl)
                window.localStorage.setItem('orgCode', res.orgCode)
                window.localStorage.setItem('email', res.email)
                window.localStorage.setItem('mobile', res.mobile)
                window.localStorage.setItem('headPictureUrl', res.headPictureUrl)
                window.localStorage.setItem('isEmailValidated', res.isEmailValidated)
                window.localStorage.setItem('isMobileValidated', 1)
                window.localStorage.setItem('loginUserName', res.userName)
                window.localStorage.setItem('domainName', res.domainName)
                window.localStorage.setItem('isInlay', 1)
                window.localStorage.setItem('sex', res.sex)
                window.localStorage.setItem('ouId', res.ouId)
                window.localStorage.setItem('ouName', res.ouName)
                window.localStorage.setItem('positionId', res.positionId)
                window.localStorage.setItem('positionName', res.positionName)
                window.localStorage.setItem('orgGroupId', res.orgGroupId)
                window.localStorage.setItem('isMasterStation', res.isMasterStation)
                window.localStorage.setItem('userIdEntityType', res.userIdEntityType)

                getAuth(res.orgId)
                    .then(({ code }) => {
                        window.localStorage.setItem('authList', code)
                    })
                    .catch(err => {
                        next()
                        NProgress.done()
                    })
            })
            .catch(err => {
                console.log('err-------', err)
            })
    }
    next()
    NProgress.done()
})

router.afterEach(({ meta }) => {
    let title
    if (meta.title === '社区') {
        title = '社区'
    } else {
        title = store.getters['cmooc/title'] || meta.title
    }
    document.title = title
})

export default router
